import React, { useState } from "react";
import axios from "axios";

import Seo from '../components/seo';
import Layout from "../components/Layout";

import * as S from "../components/StyledPayment";

function Payment() {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
      
    axios({
      method: "post",
      url: "https://getform.io/f/8774dd29-ccb5-4624-8bcf-734cfc862cb7",
      data: new FormData(form)
    })
      .then(r => {
        alert("Contato enviado com sucesso!!!");
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        alert("Uhhh! Elgo deu errado, tente novamente!!!");
        handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <Layout>
    <Seo title="Confirmar escolha de curso" />
      <S.PaymentWrapper>
        <S.PaymentSection>
          <S.PaymentTexts>
            <S.PaymentTitle>Escolha a forma de pagamento!</S.PaymentTitle>
            <S.PaymentDescription>
                Se deseja tirar alguma dúvida ou saber em primeira mão sempre que surgir novidades sobre o mundo da
                tecnologia digite seu E-mail.<br/>
                Mas se não quiser, não tem problema é continuar com a compra sem digitar seu E-mail!
            </S.PaymentDescription>
          </S.PaymentTexts>
          <S.PaymentForm onSubmit={handleOnSubmit}>
            <input type="text" name="name" placeholder="Seu nome" />
            <input type="email" name="email" placeholder="Digite seu melhor E-mail" />
            <button className="Payment-button" type="submit">
              Enviar
            </button>
          </S.PaymentForm>
        </S.PaymentSection>
      </S.PaymentWrapper>
    </Layout>
  );
}

export default Payment;
