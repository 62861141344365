import styled from 'styled-components';
import media from "styled-media-query";

export const PaymentWrapper = styled.div`
    width: 100%;
    max-width: 400px;
    margin: 80px auto;
    padding: 30px;
    border-radius: 8px;
    background: var(--color-background-second);
    border-radius: 8px;
    border: 1px solid #444;

    ${media.lessThan("medium")`
        width: auto;
        margin: 40px 20px;
        padding: 15px;
    `}
`

export const PaymentSection = styled.section`
    text-align: left;
`

export const PaymentTexts = styled.div`
    width: 100%;
`

export const PaymentTitle = styled.h1`
    color: var(--color-secondary);
    font-size: 2rem;
    margin: 0;
    
    ${media.lessThan("medium")`
      font-size: 1.5rem;
    `}
`

export const PaymentDescription = styled.p`
    font-size: 1rem;
    margin: 10px 0;
    
    ${media.lessThan("medium")`
      font-size: 1rem;
    `}
`

export const PaymentForm = styled.form`
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    text-align: left;
    gap: 20px;

    input {
        color: #FFF;
        height: 48px;
        padding: 0 15px;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid var(--color-secondary);
        background: transparent;
    }

    button {
        width: 100%;
        height: 48px;
        color: var(--color-background);
        background: var(--color-secondary);
        border-radius: 0.4rem;
        padding: 0.4rem 1.5rem;
        border: 0.1rem solid var(--color_text);
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: 0.2s ease-in;

        &:hover {
            background: transparent;
            color: var(--color-text);
        }
    }

    ${media.lessThan("medium")`
        grid-template-columns: 1fr;
    `}
`